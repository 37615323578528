import React from 'react';
import '../../i18/i18-translation';
import './index.css';
import ru from "date-fns/locale/ru";
import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn';
import {withTranslation} from 'react-i18next';
import * as DateFnsLocaleCalendar from 'date-fns/locale';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import ym from 'react-yandex-metrika';
import {ReactComponent as StarIsLoading} from '../../assets/icons/start-is-loading.svg';
import ISVG from '../../assets/icons/symbols-icon.svg';
import RestApi from '../../utils/http';
import ResultTicket from './components/ResultTicket';
import PhoneInputField from '../../components/PhoneInputField';
import {monthValues} from "../../locale/montValues";
import ModalRulesService from "../../components/ModalRulesService";
import MobileCrossLinks from "./components/MobileCrossLinks";
import DesktopCrossLinks from "./components/DesktopCrossLinks";
import ModalTariffsDescription from "./components/ModalTariffsDescription";
import {getDaysBetweenDates} from "../../utils/helpers";
import {LAST_AVAILABLE_DEPART_DAY} from "../../utils/constants";

class AeroexpressBusiness extends React.Component {
    renderValueText(value) {
        if(value === 'null') {
            return <span>{this.props.t('Выберите из списка')}</span>
        }
        return (
            value.name
                .split(/\s*[-|―]\s*/)
                .map((s, idx) => <span key={`${value.id}-${idx}`}>{s.replace(/Бизнес\s+/, '')}&nbsp;</span>)
        );
    }

    groupTariffs(data) {
        data.filter((item) => !!item.seats_select).forEach((item) => {
            let sitems = this.state.gropSeatsSelect.get(item.name.trim().split(/\s+/)[0]);
            const idx = item.name.trim().split(/\s+/)[0];
            if (!sitems) {
                sitems = this.state.gropSeatsSelect
                    .set(idx, { id: idx, name: idx, seats_select: true, items: [] })
                    .get(idx);
            }
            sitems.items.push(item);
        });
        return data
            .filter((item) => !item.seats_select)
            .concat(Array.from(this.state.gropSeatsSelect.values()));
    }

    constructor(props, context) {
        super(props, context);
        const departDefaultDate = new Date();
        const countDaysBetweenToDayAndDateTrip = getDaysBetweenDates(departDefaultDate, departDefaultDate);

        this.emailField = React.createRef();
        this.state = {
            ...props,
            errors: false,
            lang: document.documentElement.lang,
            gropSeatsSelect: new Map(),
            Aeroticket: {
                depart: departDefaultDate,
                tickets: 1,
                tariff: 'null',
                schedule: 'null',
                time: 'null',
                email: '',
                personinfo: false,
            },
        };

        ru.localize.month = buildLocalizeFn({
            values: monthValues,
            defaultWidth: 'wide',
            defaultFormattingWidth: 'wide'
        })
        this.LocaleCalendar = new Map(Object.entries(DateFnsLocaleCalendar));
        Array.from(Object.values(DateFnsLocaleCalendar), (item) => this.LocaleCalendar.set(item.code, item));

        if (!this.state.PublicTariffs)
            RestApi.get('public/tariffs')
                .catch((err) => this.setState({ ...this.state, errors: err, loading: false }))
                .then((response) => response.data)
                .then((resource) => {
                    const groupedTariffs = this.groupTariffs(resource.data);
                    const filteredTariffs = groupedTariffs.filter((tariff) => tariff.days_to >= countDaysBetweenToDayAndDateTrip && tariff.days_from <= countDaysBetweenToDayAndDateTrip);
                        this.setState({
                            ...this.state,
                            PublicTariffs: groupedTariffs,
                            filteredTariffs: filteredTariffs,
                        })
                });
    }

    updateLangState() {
        RestApi.get('public/tariffs')
            .catch((err) => this.setState({ ...this.state, errors: err, loading: false }))
            .then((response) => response.data)
            .then((resource) =>
                this.setState(
                    { ...this.state, PublicTariffs: this.groupTariffs(resource.data) },
                    this.getTariffsFreeSeats,
                ),
            );
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {};
    }

    componentDidMount() {
        const observer = new MutationObserver(() => {
            this.props.i18n.changeLanguage(document.documentElement.lang, () =>
                this.setState(
                    {
                        ...this.state,
                        gropSeatsSelect: new Map(),
                        PublicTariffs: undefined,
                        Aeroticket: {
                            ...this.state.Aeroticket,
                            tickets: 1,
                            tariff: 'null',
                            schedule: 'null',
                            time: 'null',
                        },
                        lang: document.documentElement.lang,
                    },
                    this.updateLangState,
                ),
            );
        });
        observer.observe(document.documentElement, { attributeFilter: ['lang'] });
    }

    getTariffsFreeSeats(e) {
        this.state.Aeroticket.schedule instanceof Object &&
            Number(this.state.Aeroticket.schedule.id) &&
            this.state.Aeroticket.depart instanceof Date &&
            RestApi.get(
                `public/tariffs/${this.state.Aeroticket.schedule.id}/free-seats/${
                    this.state.Aeroticket.depart.toISOString().split('T')[0]
                }`,
            )
                .catch((err) => this.setState({ ...this.state, errors: err, loading: false }))
                .then((response) => response.data)
                .then((resource) => this.setState({ ...this.state, PublicFreeSeats: resource.data }));
    }

    selectDepartDate(dateTrip) {
        if (dateTrip !== 'Invalid Date') {
            const countDaysBetweenToDayAndDateTrip = dateTrip ? getDaysBetweenDates(new Date(), dateTrip): null;
            const filteredTariffs = (countDaysBetweenToDayAndDateTrip || countDaysBetweenToDayAndDateTrip === 0) ? this.state.PublicTariffs.filter((tariff) => tariff.days_from <= countDaysBetweenToDayAndDateTrip && tariff.days_to >= countDaysBetweenToDayAndDateTrip): [];
            this.setState(
                {
                    ...this.state,
                    Aeroticket: {
                        ...this.state.Aeroticket,
                        depart: dateTrip ? new Date(dateTrip): 'null',
                        time: 'null',
                        tariff: 'null',
                        tickets: 1,
                    },
                    filteredTariffs,
                },
                this.getTariffsFreeSeats,
            );
        }
    }

    selectTariff(e) {
        this.setState({
            ...this.state,
            PublicFreeSeats: false,
            Aeroticket: {
                ...this.state.Aeroticket,
                tariff: e.target.value,
                schedule: 'null',
                time: 'null',
                tickets: 1,
            },
        });
    }

    selectTariffSchedule(e) {
        this.setState(
            {
                ...this.state,
                Aeroticket: {
                    ...this.state.Aeroticket,
                    time: 'null',
                    schedule: e.target.value,
                },
            },
            this.getTariffsFreeSeats,
        );
    }

    selectTariffScheduleTime(e) {
        this.setState({
            ...this.state,
            Aeroticket: {
                ...this.state.Aeroticket,
                tickets: 1,
                time: e.target.value,
            },
        });
    }

    prevTicketsCount() {
        let t = Number(this.state.Aeroticket.tickets || 1);
        if (this.state.Aeroticket.time instanceof Object) {
            t = this.state.Aeroticket.time.seats >= --t ? t : this.state.Aeroticket.time.seats;
        } else {
            t -= 1;
        }
        if (t < 0) {
            t = 1;
        }
        this.setState({
            ...this.state,
            Aeroticket: { ...this.state.Aeroticket, tickets: t || 1 },
        });
    }

    nextTicketsCount() {
        const maxCountTickets = this.state.Aeroticket.time.seats || this.state.Aeroticket.tariff.max_tickets || 1;
        const nextTicketCountValue = this.state.Aeroticket.tickets + 1;
        if(nextTicketCountValue <= maxCountTickets) {
            this.setState({
                ...this.state,
                Aeroticket: { ...this.state.Aeroticket, tickets: nextTicketCountValue },
            });
        }
    }

    inputTickets(e) {
        const maxCountTickets = this.state.Aeroticket.time.seats || this.state.Aeroticket.tariff.max_tickets || 1;
        const numberValue = Number(e.target.value?.replace(/[^0-9]/g, ''));
        if(numberValue <= maxCountTickets) {
            this.setState({
                ...this.state,
                Aeroticket: { ...this.state.Aeroticket, tickets: numberValue },
            });
        }
    }

    inputEmail(e) {
        e.target.setCustomValidity(
            e.target.validity.patternMismatch ? this.props.t('Введите данные в указанном формате') : '',
        );
        this.setState({
            ...this.state,
            Aeroticket: { ...this.state.Aeroticket, email: e.target.value },
        });
    }

    toggleInfoPerson(e) {
        this.setState({
            ...this.state,
            Aeroticket: {
                ...this.state.Aeroticket,
                personinfo: !this.state.Aeroticket.personinfo,
            },
        });
        return e.stopPropagation();
    }

    toggleStop(e) {
        return e.preventDefault() || e.stopPropagation();
    }

    isValidateForm() {
        return this.isActiveFirstStep() && this.isActiveLastStep() && this.state.Aeroticket.personinfo;
    }

    isActiveFirstStep() {
        return (
            this.state.Aeroticket.tickets > 0 &&
            (this.state.Aeroticket.schedule instanceof Object
                ? this.state.Aeroticket.time instanceof Object
                : this.state.Aeroticket.tariff instanceof Object &&
                  this.state.Aeroticket.tariff.id &&
                  !this.state.Aeroticket.tariff.seats_select) &&
            this.state.Aeroticket.depart instanceof Date
        );
    }

    isActiveLastStep() {
        return (
            this.state.Aeroticket.phone &&
            this.state.Aeroticket.email &&
            this.emailField.current.querySelector('input').validity.valid
        );
    }

    isActiveNext(set) {
        switch (set) {
            case 1:
                return this.isActiveFirstStep() && !this.isActiveLastStep();
            case 2:
                return this.isActiveFirstStep() && !!this.isActiveLastStep();
            default:
                return !this.isActiveFirstStep();
        }
    }

    isSchedule() {
        return (
            this.state.Aeroticket.tariff.seats_select &&
            this.state.gropSeatsSelect.size > 0 &&
            (this.state.gropSeatsSelect.get(this.state.Aeroticket.tariff.id) || {}).items
        );
    }

    payAeroticket() {
        if (this.isValidateForm()) {
            const datastate = {
                tariff_id:
                    this.state.Aeroticket.time instanceof Object
                        ? this.state.Aeroticket.schedule.id
                        : this.state.Aeroticket.tariff.id,
                depart_date: this.state.Aeroticket.depart.toISOString().split('T')[0],
                tickets_count: this.state.Aeroticket.tickets,
                email: this.state.Aeroticket.email,
                phone: `+${this.state.Aeroticket.phone}`,
            };
            if (this.state.Aeroticket.time instanceof Object)
                datastate.schedule_id = this.state.Aeroticket.time.sched_id;
            this.setState({ ...this.state, loading: true }, () =>
                RestApi.post('public/orders', JSON.stringify(datastate), {
                    headers: { 'Content-Type': 'application/json' },
                })
                    .catch((err) => this.setState({ ...this.state, errors: err, loading: false }))
                    .then((response) => response.data)
                    .then((resource) => {
                        ym('reachGoal', 'BOOKING');
                        this.goPayAeroticket(resource.data);
                    }),
            );
        }
    }

    goPayAeroticket(data) {
        const form = document.body.appendChild(document.createElement('form'));
        form.action = data.url;
        form.method = data.method;
        for (const property in data.params) {
            const input = form.appendChild(document.createElement('input'));
            input.type = 'hidden';
            input.name = property;
            input.value = data.params[property];
        }
        this.setState({ ...this.state, loading: false });
        form.submit();
    }

    closeErrorLayer(e) {
        this.setState({ ...this.state, errors: !this.state.errors });
        return e.preventDefault() || e.stopPropagation();
    }

    changePhone(phone) {
        this.setState({ ...this.state, Aeroticket: { ...this.state.Aeroticket, phone } });
    }

    render() {
        return (
            <main>
            <article className="Aeroexpress-business">
                <div id="modal-error" className="modal-error" data-open={!!this.state.errors || undefined}>
                    <section>
                        <del onClick={this.closeErrorLayer.bind(this)}>
                            <svg>
                                {' '}
                                <use href={`${ISVG}#set-close`} />{' '}
                            </svg>
                        </del>
                        <h2>{this.props.t('Ошибка сервера')}</h2>
                        <p>
                            {this.props.t(
                                this.state.errors
                                    ? this.state.errors.message
                                    : 'Произошла ошибка, пожалуйста, повторите попытку.',
                            )}
                        </p>
                        <div>
                            <button className="active" onClick={this.closeErrorLayer.bind(this)}>
                                {this.props.t('Закрыть')}
                            </button>
                        </div>
                    </section>
                </div>
                <div id="modal-loading" className="modal-loading" data-open={this.state.loading || undefined}>
                    {' '}
                    <i>
                        <StarIsLoading />
                    </i>{' '}
                </div>
                <ModalRulesService
                    isShowModal={this.props.isShowRulesModal}
                    toggleOpenModal={this.props.toggleRulesModal.bind(this)}
                    lang={this.state.lang}
                    toggleStop={this.toggleStop.bind(this)}
                />
                <div
                    id="modal-info"
                    className="modal-info"
                    data-open={this.props.isShowTariffsModal || undefined}
                    onClick={this.props.toggleTariffsModal.bind(this)}
                >
                    <section onClick={this.toggleStop.bind(this)}>
                        <div onClick={this.props.toggleTariffsModal.bind(this)}>
                            <s />
                            <dfn>← {this.props.t('Назад')}</dfn>
                        </div>
                        <del onClick={this.props.toggleTariffsModal.bind(this)}>
                            <svg>
                                {' '}
                                <use href={`${ISVG}#set-close`} />{' '}
                            </svg>
                        </del>
                        <h2>{this.props.t('Описание тарифов и их стоимость')}</h2>
                        <ModalTariffsDescription tariffs={this.state.PublicTariffs} />
                    </section>
                </div>
                <ResultTicket
                    setLoading={(arg) => {
                        this.setState({ ...this.state, loading: arg });
                    }}
                    setError={(err) => {
                        this.setState({ ...this.state, errors: err });
                    }}
                />
                <h2>
                    <span> {this.props.t('Аэроэкспресс Бизнес')} </span>
                    <div>
                        <dfn>
                            {this.props.t('Продажа билетов на')} {this.props.t('Аэроэкспресс')}{' '}
                            {this.props.t('для организаций и индивидуальных предпринимателей')}
                        </dfn>
                    </div>
                </h2>
                <DesktopCrossLinks />
                <section
                    className={[
                        ((this.isActiveNext(0) && 'first-step') || (this.isActiveNext(1) && 'last-step')).toString(),
                        !!this.isSchedule() && 'is-schedule',
                    ].join(' ')}
                >
                    <ol>
                        <li className={(this.isActiveNext(0) && 'active').toString()}>
                            <h3>{this.props.t('Дата, тариф и количество билетов')}</h3>
                            <section className={(!!this.isSchedule() || 'is-not-schedule').toString()}>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={this.LocaleCalendar.get(this.props.i18n.language)}
                                >
                                    <KeyboardDatePicker
                                        variant="inline"
                                        inputVariant="outlined"
                                        disableToolbar
                                        disablePast
                                        format="dd.LL.yyyy"
                                        margin="normal"
                                        required
                                        id="settings-date-of-travel"
                                        label={this.props.t('Дата поездки')}
                                        value={this.state.Aeroticket.depart}
                                        onChange={this.selectDepartDate.bind(this)}
                                        autoOk
                                        maxDate={LAST_AVAILABLE_DEPART_DAY}
                                        invalidDateMessage={'Дата указана неверно'}
                                        minDateMessage={'Указаная дата раньше допустимой'}
                                        maxDateMessage={'Указаная дата позже допустимой'}
                                    />
                                </MuiPickersUtilsProvider>
                                <FormControl variant="outlined">
                                    <InputLabel id="settings-rate-label">{this.props.t('Тариф')}</InputLabel>
                                    <Select
                                        renderValue={this.renderValueText.bind(this)}
                                        labelId="settings-rate-label"
                                        id="settings-rate"
                                        defaultValue={null}
                                        value={this.state.Aeroticket.tariff}
                                        onChange={this.selectTariff.bind(this)}
                                        required
                                        label={this.props.t('Тариф')}
                                        disabled={this.state.Aeroticket.depart === 'null' || this.state.Aeroticket.depart.toString() === 'Invalid Date' || this.state.Aeroticket.depart.getTime() > LAST_AVAILABLE_DEPART_DAY.getTime() ||
                                            this.state.Aeroticket.depart.getTime() < new Date().setHours(0,0,0, 0)
                                        }
                                    >
                                        {this.state.filteredTariffs &&
                                            this.state.filteredTariffs.map((item) => (
                                                <MenuItem key={item.id} value={item}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    <i onClick={this.props.toggleTariffsModal.bind(this)}>
                                        {' '}
                                        <svg>
                                            {' '}
                                            <use href={`${ISVG}#set-warn`} />{' '}
                                        </svg>{' '}
                                    </i>
                                </FormControl>
                                <TextField
                                    id="settings-count"
                                    label={this.props.t('Количество')}
                                    disabled={
                                    !(this.state.Aeroticket.time.seats || this.state.Aeroticket.tariff.max_tickets) ||
                                    ((this.state.Aeroticket.time.seats || this.state.Aeroticket.tariff.max_tickets) === 1) || this.state.Aeroticket.tariff === 'null'}
                                    placeholder="0"
                                    step={1}
                                    min={0}
                                    variant="outlined"
                                    required
                                    value={this.state.Aeroticket.tickets}
                                    onChange={this.inputTickets.bind(this)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <s onClick={this.nextTicketsCount.bind(this)} />
                                                <s onClick={this.prevTicketsCount.bind(this)} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {this.state.Aeroticket.tariff && (
                                    <FormControl variant="outlined">
                                        <InputLabel id="settings-route-label">{this.props.t('Маршрут')}</InputLabel>
                                        <Select
                                            renderValue={this.renderValueText.bind(this)}
                                            labelId="settings-route-label"
                                            id="settings-route"
                                            defaultValue={null}
                                            value={this.state.Aeroticket.tariff && this.state.Aeroticket.schedule}
                                            onChange={this.selectTariffSchedule.bind(this)}
                                            required
                                            label={this.props.t('Маршрут')}
                                        >
                                            <MenuItem value="[object Object]" style={{ display: 'none' }}>
                                                {' '}
                                                <dfn>{this.props.t('Из списка')}</dfn>{' '}
                                            </MenuItem>
                                            <MenuItem value={null} style={{ display: 'none' }}>
                                                {' '}
                                                <dfn>{this.props.t('Из списка')}</dfn>{' '}
                                            </MenuItem>
                                            {this.isSchedule() instanceof Array &&
                                                this.isSchedule().map((item) => (
                                                    <MenuItem key={item.id} value={item}>
                                                        {item.name.replace(/(.+?)\s+/, '')}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                )}
                                {this.state.Aeroticket.tariff && (
                                    <FormControl variant="outlined">
                                        <InputLabel id="settings-several-time-label">
                                            {this.props.t('Время отпр.')}
                                        </InputLabel>
                                        <Select
                                            labelId="settings-several-time-label"
                                            id="settings-several-time"
                                            disabled={
                                                !this.state.PublicFreeSeats || this.state.PublicFreeSeats.length === 0
                                            }
                                            defaultValue={null}
                                            value={this.state.Aeroticket.tariff && this.state.Aeroticket.time}
                                            onChange={this.selectTariffScheduleTime.bind(this)}
                                            required
                                            label={this.props.t('Время отпр.')}
                                        >
                                            <MenuItem value="[object Object]" style={{ display: 'none' }}>
                                                {' '}
                                                <dfn>{this.props.t('Из списка')}</dfn>{' '}
                                            </MenuItem>
                                            <MenuItem value={null} style={{ display: 'none' }}>
                                                {' '}
                                                <dfn>{this.props.t('Из списка')}</dfn>{' '}
                                            </MenuItem>
                                            {this.state.PublicFreeSeats instanceof Array &&
                                                this.state.PublicFreeSeats.filter((item) => item.seats > 0).map(
                                                    (item) => (
                                                        <MenuItem key={item.sched_id} value={item}>
                                                            {item.trip}
                                                        </MenuItem>
                                                    ),
                                                )}
                                        </Select>
                                    </FormControl>
                                )}
                            </section>
                        </li>
                        <li className={(!!this.isActiveNext(1) && 'active').toString()}>
                            <h3>{this.props.t('Контактные данные')}</h3>
                            <section>
                                <TextField
                                    error={
                                        !!(
                                            this.emailField.current &&
                                            this.emailField.current.querySelector('input').value &&
                                            this.emailField.current.querySelector('input').validationMessage
                                        )
                                    }
                                    helperText={
                                        this.emailField.current &&
                                        this.emailField.current.querySelector('input').value &&
                                        this.emailField.current.querySelector('input').validationMessage
                                    }
                                    ref={this.emailField}
                                    id="profile-email"
                                    label={this.props.t('Ваш e-mail')}
                                    required
                                    type="email"
                                    inputProps={{
                                        pattern: '^[a-zA-Z0-9-\\._]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,}$',
                                    }}
                                    placeholder={this.props.t('Введите ваш e-mail')}
                                    variant="outlined"
                                    value={this.state.Aeroticket.email}
                                    onChange={this.inputEmail.bind(this)}
                                />
                                <PhoneInputField onChange={this.changePhone.bind(this)} />
                            </section>
                        </li>
                        <li className={(!!this.isActiveNext(2) && 'active').toString()}>
                            <h3>{this.props.t('Ваш заказ')}</h3>
                            <section>
                                <div className={'totalData'}>
                                    <span>
                                        <dfn>
                                            <i>
                                                {' '}
                                                <svg>
                                                    {' '}
                                                    <use href={`${ISVG}#calendar`} />{' '}
                                                </svg>{' '}
                                            </i>
                                            <span>{this.props.t('Дата поездки')}</span>
                                        </dfn>
                                        <span>
                                            {this.state.Aeroticket.depart &&
                                                this.state.Aeroticket.depart.toLocaleString('ru', {
                                                    year: 'numeric',
                                                    month: 'numeric',
                                                    day: 'numeric',
                                                })}
                                        </span>
                                    </span>

                                    <span>
                                        <dfn>
                                            <i>
                                                {' '}
                                                <svg>
                                                    {' '}
                                                    <use href={`${ISVG}#time`} />{' '}
                                                </svg>{' '}
                                            </i>
                                            <span>{this.props.t('Время')}</span>
                                        </dfn>
                                        <span>
                                            {this.state.Aeroticket.schedule instanceof Object &&
                                                this.state.Aeroticket.time.trip}
                                        </span>
                                    </span>

                                    <span>
                                        <dfn>
                                            <i>
                                                {' '}
                                                <svg>
                                                    {' '}
                                                    <use href={`${ISVG}#airplane`} />{' '}
                                                </svg>{' '}
                                            </i>
                                            <span>{this.props.t('Аэропорт')}</span>
                                        </dfn>
                                        <span>
                                            {this.state.Aeroticket.schedule instanceof Object &&
                                                this.state.Aeroticket.schedule.name.split(/\s+/)[1]}
                                        </span>
                                    </span>

                                    <span>
                                        <dfn>
                                            <i>
                                                {' '}
                                                <svg>
                                                    {' '}
                                                    <use href={`${ISVG}#counts`} />{' '}
                                                </svg>{' '}
                                            </i>
                                            <span>{this.props.t('Кол-во билетов')}</span>
                                        </dfn>
                                        <span>{this.state.Aeroticket.tickets}</span>
                                    </span>
                                </div>

                                <span>
                                    <dfn>
                                        <i>
                                            {' '}
                                            <svg>
                                                {' '}
                                                <use href={`${ISVG}#flag`} />{' '}
                                            </svg>{' '}
                                        </i>
                                        <span>{this.props.t('Тариф')}</span>
                                    </dfn>
                                    <span>
                                        {(this.state.Aeroticket.schedule instanceof Object &&
                                            this.state.Aeroticket.schedule.name) ||
                                            (this.state.Aeroticket.tariff instanceof Object &&
                                                this.state.Aeroticket.tariff.name)}
                                    </span>
                                </span>
                                <div>
                                    <hr />
                                    <label>
                                        <input
                                            type="checkbox"
                                            onChange={this.toggleInfoPerson.bind(this)}
                                            checked={this.state.Aeroticket.personinfo}
                                        />
                                        <dfn>
                                            {this.props.t('Согласен на обработку персональных данных и ознакомлен с')}{' '}
                                            <a
                                                onClick={this.props.toggleRulesModal.bind(this)}
                                            >
                                                {this.props.t('правилами сервиса')}
                                            </a>
                                        </dfn>
                                    </label>
                                    <ruby>
                                        <rt>{this.props.t('Итого к оплате')}</rt>
                                        <span>
                                            {this.state.Aeroticket.tariff instanceof Object &&
                                                (
                                                    this.state.Aeroticket.tickets *
                                                    (this.state.Aeroticket.time instanceof Object
                                                        ? this.state.Aeroticket.schedule.price
                                                        : this.state.Aeroticket.tariff.price)
                                                )
                                                    .toLocaleString('ru-RU', {
                                                        style: 'currency',
                                                        currency: 'RUB',
                                                    })
                                                    .replace(/[,].*/, '')}
                                        </span>
                                    </ruby>
                                    <button
                                        className={(!!this.isValidateForm() && 'active').toString()}
                                        onClick={this.payAeroticket.bind(this)}
                                    >
                                        {this.props.t('Оплатить')}
                                    </button>
                                </div>
                            </section>
                        </li>
                    </ol>
                </section>
                <MobileCrossLinks />
            </article>
            </main>
        );
    }
}

export default withTranslation()(AeroexpressBusiness);
